<template>
  <div class="home-page">
    <section class="img-width-wrap">
      <div class="row home-section-1">
        <div class="col-md-4">
          <img src="../assets/images/banner1.jpg" alt="" />
        </div>
        <div class="col-md-4">
          <img src="../assets/images/banner2.jpg" alt="" />
          <img src="../assets/images/banner3.jpg" alt="" />
        </div>
        <div class="col-md-4">
          <img src="../assets/images/banner4.jpg" alt="" />
        </div>
      </div>
    </section>
    <section class="section-limit home-section-2 bg-image bg-image-home">
      <div class="container container-sm container-lg container-xs">
        <div class="row">
          <div class="col-md-6 section-xl">
            <h2>获取最新产品目录</h2>
            <p>
              每个月为您提供最新的产品目录，以便您随时了解最新的极客范儿十足、设计感爆棚的新品信息！
            </p>
            <a href="#">Shop now!</a>
          </div>
        </div>
      </div>
    </section>
    <section class="img-width-wrap home-section-3 section-xl text-center">
      <div class="container container-sm container-lg container-xs">
        <h2>热门产品</h2>
        <div class="row">
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img src="../assets/images/67_PoissonDistribution_White.png" alt="" />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  泊松分布马克杯
                </div>
                <div class="product-title en-related-title">POISSON DISTRIBUTION</div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="product-icon-wrap">
                  <span class="bi-heart"></span>
                </div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img src="../assets/images/59_Euler'sFormula_Full.png" alt="" />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  欧拉公式啤酒杯
                </div>
                <div class="product-title en-related-title">EULER'S FORMULA</div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img
                  src="../assets/images/60_GaussianNormalDistribution_Black.png"
                  alt=""
                />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  高斯正态分布马克杯
                </div>
                <div class="product-title en-related-title">
                  GAUSSIAN NORMAL DISTRIBUTION
                </div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img src="../assets/images/39_VanderWaalsequation.png" alt="" />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  范德瓦尔斯方程搪瓷杯
                </div>
                <div class="product-title en-related-title">VAN DER WAALS EQUATION</div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="img-width-wrap section-limit">
      <div class="row justify-content-md-center">
        <div class="mb12 col-md-6 col-lg-4">
          <img src="../assets/images/hot1.jpg" alt="" width="573" height="432" />
        </div>
        <div class="mb12 col-md-6 col-lg-4">
          <img src="../assets/images/hot2.jpg" alt="" width="573" height="432" />
        </div>
        <div class="mb12 col-md-6 col-lg-4">
          <img src="../assets/images/hot3.jpg" alt="" width="573" height="432" />
        </div>
      </div>
    </section>
    <section class="section-xl text-center">
      <div class="container container-sm container-lg container-xs">
        <div class="row row-50 justify-content-md-center">
          <div class="col-md-6 col-lg-4">
            <div class="introduce">
              <div class="introduce-icon">
                <span class="bi-clock"></span>
              </div>
              <h4 class="introduce-title">7天无理由退货</h4>
              <p class="introduce-info">
                我们的7天无理由退货政策使您安心购买，挑选最符合您的独特个人气质的杯子吧
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="introduce">
              <div class="introduce-icon">
                <span class="bi-truck"></span>
              </div>
              <h4 class="introduce-title">运费全免</h4>
              <p class="introduce-info">
                即时发货，免运费，让您以最快的速度拥有独一无二的心爱之物
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="introduce">
              <div class="introduce-icon">
                <span class="bi-bookmark-star"></span>
              </div>
              <h4 class="introduce-title">设计大奖</h4>
              <p class="introduce-info">
                您购买的是获得过设计界大奖的产品，它令您生活工作环境与众不同
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container container-sm container-lg container-xs">
      <div class="line"></div>
    </div>

    <section class="img-width-wrap home-section-3 section-xl text-center">
      <div class="container container-sm container-lg container-xs">
        <h2>热门产品</h2>
        <div class="row">
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img src="../assets/images/67_PoissonDistribution_White.png" alt="" />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  泊松分布马克杯
                </div>
                <div class="product-title en-related-title">POISSON DISTRIBUTION</div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img src="../assets/images/59_Euler'sFormula_Full.png" alt="" />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  欧拉公式啤酒杯
                </div>
                <div class="product-title en-related-title">EULER'S FORMULA</div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img
                  src="../assets/images/60_GaussianNormalDistribution_Black.png"
                  alt=""
                />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  高斯正态分布马克杯
                </div>
                <div class="product-title en-related-title">
                  GAUSSIAN NORMAL DISTRIBUTION
                </div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="product-grid product-item">
              <div class="product-img-wrap">
                <img src="../assets/images/39_VanderWaalsequation.png" alt="" />
              </div>
              <div>
                <div class="product-title zh-related-title">
                  范德瓦尔斯方程搪瓷杯
                </div>
                <div class="product-title en-related-title">VAN DER WAALS EQUATION</div>
                <div class="product-price">
                  <span class="money-wrap">
                    <span class="money-flag1">¥ </span
                    ><span class="money-num1">
                      49<span class="price-decimals1">.90</span>
                    </span>
                  </span>
                </div>
                <div class="button">购买</div>
                <div class="rating-list">
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star-fill" />
                  <span class="star-box bi-star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="img-width-wrap home-section-4 text-center">
      <div class="container container-sm container-lg container-xs">
        <h2>关于杯子与软件开发,<br />我们有很多要说的话</h2>
        <div class="row row-50 justify-content-md-center">
          <div class="col-md-6 col-lg-4">
            <img src="../assets/images/post-index-1-418x315.jpg" alt="" />
            <h5>茶杯与软件开发的紧密联系</h5>
            <span>Jan.2, 2021 | by 杯言杯语</span>
          </div>
          <div class="col-md-6 col-lg-4">
            <img src="../assets/images/post-index-2-418x315.jpg" alt="" />
            <h5>咖啡因对软件开发的决定性作用</h5>
            <span>Jan.2, 2021 | by 杯言杯语</span>
          </div>
          <div class="col-md-6 col-lg-4">
            <img src="../assets/images/post-index-3-418x315.jpg" alt="" />
            <h5>杯子与容器的关系</h5>
            <span>Jan.2, 2021 | by 杯言杯语</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section-xl home-section-5">
      <div class="container container-sm container-lg container-xs">
        <div class="row">
          <div class="mb12 col-lg-4">
            <h2>订阅我们的邮件列表</h2>
          </div>
          <div class="col-lg-8">
            <div class="flex justify-content-space-between">
              <input class="form-control" placeholder="请输入您的邮箱地址" type="email" />
              <div class="button button-primary">订阅</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'Home'
  };
</script>

<style lang="scss" scoped>
  @import '../assets/home.scss';
</style>
